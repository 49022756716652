<template>
  <section class="">
    <div class="ebmp_box_privacy">
      <div class="title">소중한 <span class="forg">개인정보!</span><br />
        비밀번호를 변경하여 지켜주세요.</div>
      <span class="k-icon k-i-lock title_img"></span>
      <div class="txt_small">3개월 동안 비밀번호를 변경하지 않으셨네요!<br />
        조금 불편하시더라도 지금 비밀번호를 변경하시면<br />개인정보 피해를 막을 수 있습니다.</div>
      <ul class="filter_list">
        <li class="w100">
          <dt>현재 비밀번호</dt>
          <input ref="originalPasswordRef"
                 v-model="originalPwd"
                 class="k-textbox"
                 :style="'width: 100%;'"
                 maxlength="50"
                 autocomplete="off"
                 @keypress.enter="onChangePassword"
                 placeholder="현재 비밀번호를 입력해 주세요."
                 type="password" />
        </li>
        <li class="w100">
          <dt>새 비밀번호 입력</dt>
          <input
              ref="newPwdRef"
              type="password"
              class="k-textbox"
              :style="'width: 100%;'"
              :placeholder="'비밀번호를 입력하세요.'"
              autocomplete="off"
              @keypress.enter="onChangePassword"
              v-model.trim="newPwd"/>
          <div v-show="pwdValidator" class="msg_red">비밀번호는 8자 이상의 문자,숫자,특문으로 작성해주세요.</div>
        </li>
        <li class="w100">
          <dt>새 비밀번호 확인</dt>
          <input
              ref="newPwdConfirmRef"
              type="password"
              class="k-textbox"
              :style="'width: 100%;'"
              :placeholder="'비밀번호를 재입력하세요.'"
              autocomplete="off"
              @keypress.enter="onChangePassword"
              v-model.trim="newPwdConfirm"/>
          <div v-show="newPwdConfirm ? newPwd !== newPwdConfirm : false" class="msg_red">새 비밀번호와 맞지 않습니다. 기재한 비밀번호를 확인해주세요.</div>
        </li>
      </ul>
      <div class="info_txt">
        ※ 비밀번호는 영문, 숫자, 특수기호를 혼합한 8~20자리로 설정이 가능합니다.<br />
        ※ 기존에 사용하셨던 비밀번호는 사용하실 수 없습니다.
      </div>
      <div class="box_btn">
        <button type="submit"
                @click="onChangePassword"
                class="large_btn orange"
                :disabled="!originalPwd || !newPwd || pwdValidator || newPwd !== newPwdConfirm">네, 지금 변경 할래요.</button>
        <button type="submit" @click="onChangePasswordDate" class="large_btn mt10">아니오. 다음에 변경 할래요.(3개월)</button>
      </div>
    </div>
  </section>
</template>




<script>
import ApiUtil from '@/api/api.util'
import ApiConfig from '@/api/api.config'
import EtnersCommonUtil from '@ebmp-fe-common-module/module/src/common/etners.common.util.js'

export default {
  name: 'ChangePassword',
  computed : {
    pwdValidator : function (){
      if(!this.newPwd) return false
      const matched = /(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{1,50}).{8,50}$/.test(this.newPwd)
      // 부적합한경우 retrun true
      return !matched
    },
    storeLoginUserData: function() {
      return this.$store.getters['loginUserData']
    },
  },
  methods: {
    //다음에
    onChangePasswordDate() {
      const vm = this
      ApiUtil.patch(`${ApiConfig.efsDomain}/efs/user/password-date`).then(response => {
        if (response.data === undefined || response.data.resultStatus === undefined) {
          kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
          return false
        }
        if (response.data.resultStatus.messageCode !== '2000') {
          kendo.alert(response.data.resultStatus.messageText)
          return false
        }
        vm.beforeGo()
      })


    },
    onChangePassword() {
      const vm = this

      if(validator()){

        let param = {
          loginUserToken: vm.$store.state.loginStore.lutk,
          userId : vm.$store.getters.loginUserData.userId,
          userOriginPwd : vm.originalPwd,
          userPwd  : vm.newPwd,
        }

        vm.$store.dispatch('FETCH_UPDATE_PASSWORD', param).then(response => {

          if (response.resultData === undefined || response.resultStatus === undefined) {
            kendo.alert('데이터를 정상적으로 받아오지 못했습니다.')
            return false
          }

          if (response.resultStatus.messageCode === '2000') {
            kendo.alert("비밀번호가 변경되었습니다.").bind('close',function (){
              vm.beforeGo()
            })
            return false
          } else if (response.resultStatus.messageCode !== '2000') {
            kendo.alert(response.resultStatus.messageText)
            return false
          }

        })
      }

      function validator(){

        if(!vm.originalPwd || !vm.newPwd || vm.pwdValidator || vm.newPwd !== vm.newPwdConfirm){
          return false
        }

        if(EtnersCommonUtil.isEmpty(vm.originalPwd)){
          kendo.alert("현재 비밀번호를 입력해주세요.").bind('close', function() {
            vm.$refs.originalPasswordRef.focus()
          })
          return false
        }
        if(EtnersCommonUtil.isEmpty(vm.newPwd)){
          kendo.alert("새 비밀번호를 입력해주세요.").bind('close', function() {
            vm.$refs.newPwdRef.focus()
          })
          return false
        }
        if(EtnersCommonUtil.isEmpty(vm.newPwdConfirm)){
          kendo.alert("새 비밀번호를 확인란을 입력해주세요.").bind('close', function() {
            vm.$refs.newPwdConfirmRef.focus()
          })
          return false
        }
        return true
      }
    },
    beforeGo() {
      const vm = this

      // sassMode인경우 무조건 렌딩페이지로 이동
      if(vm.$store.state.loginStore.saasMode){
        vm.goLanding()
      }
      // 접근가능한 회사가 없는 경우
      else if(vm.storeLoginUserData?.firstAceessInfoCmpyMng.length < 1){
        kendo.alert("접근가능한 회사가 없습니다.").bind('close', function() {
          vm.goLanding()
        })
      }
      else if(vm.storeLoginUserData?.firstAceessInfoCmpyMng.length === 1
          && [Constant.efs.auth.LOGISTICS_MANAGER, Constant.efs.auth.CLIENT_USER].includes(vm.storeLoginUserData.authCd))
      {
        let accessCmpyInfoSysGbn = vm.storeLoginUserData?.firstAceessInfoCmpyMng[0].sysGbn
        let mode = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'dev-' : ''
        let linkCmpyCd
        let domain
        switch (accessCmpyInfoSysGbn) {
          case vm.customCmpyConstant.efs :
            vm.goDashBoard()
            break
          case vm.customCmpyConstant.fms :
            domain = `${mode}fms.etnersefs.com`
            linkCmpyCd = vm.storeLoginUserData?.firstAceessInfoCmpyMng[0].cmpyCd
            break
          case vm.customCmpyConstant.mega :
            domain = `${mode}mega.etnersefs.com/lgn/getSSOLogin.data`
            break;
          case vm.customCmpyConstant.cd :
            domain = `${mode}cd.etnersefs.com/lgn/getSSOLogin.data`
            break;
          case vm.customCmpyConstant.visang :
            domain = `${mode}visang.etnersefs.com/lgn/getSSOLogin.data`
            break;
        }
        if(vm.customCmpyConstant.efs !== accessCmpyInfoSysGbn ){
          window.location.href = `https://${domain}?loginUserToken=${vm.loginUserToken}${!!linkCmpyCd? '&linkCmpyCd='+linkCmpyCd : ''}`
        }
      }
      else {
        vm.goSelectCompany()
      }
    },
    goSelectCompany() {
      this.$router.push({
        path: '/enter',
      })
    },
  },
  data() {
    return {
      originalPwd : null,
      newPwd : null,
      newPwdConfirm : null,
    }

  },
}
</script>

<style scoped>
</style>
